import { PageResult } from "@/components";
import { Button, Spin } from "antd";
import classNames from "classnames";
import React from "react";

export default (props: {
  tip?: string
  loading?: boolean
  disabled?: boolean
  onOk?(): void
  okText?: string
  resultText?: string
  children?: React.ReactNode
  style?: React.CSSProperties
  contentStyle?: React.CSSProperties
  contentWrapperStyle?: React.CSSProperties
  buttons?: React.ReactNode[]
  buttonsAlign?: 'left' | 'center' | 'right'
  className?: string
}) => {

  return <div className={classNames('layout-footer-bar', {
    [props.className as string]: !!props.className
  })} style={props.style || {}}>
    <Spin size="large" tip={props.tip || '正在保存...'} spinning={props.loading === true}>
      <div className="layout-footer-bar-content-wrapper" style={props.contentWrapperStyle || {}}>
        <div className="layout-footer-bar-content" style={props.contentStyle || {}}>
          {props.children}
        </div>
      </div>
    </Spin>
    <div className="layout-footer-bar-footer" style={{textAlign: props.buttonsAlign || 'center'}}>
      {
        props.buttons && React.Children.map(props.buttons, (child, index) => {
          return React.isValidElement(child) ? React.cloneElement(child, {
            key: index
          }) : child;
        })
      }
      {
        !props.buttons &&
        <Button type="primary" disabled={props.disabled || props.loading} onClick={() => {
          props.onOk && props.onOk()
        }}>{props.okText || '保存'}</Button>
      }
    </div>
  </div>
};