import React from "react";
import { Form, Input } from 'antd';
const styles = require('../layout-main.less');

const FormGrid = (props: {
  borderStyle?: 'underline' | 'border'
  style?: React.CSSProperties
  children: React.ReactNode
}) => {
  return <div className="layout-form-grid" style={props.style || {}}>{props.children}</div>
}

FormGrid.Col = (props: {
  label: React.ReactNode
  labelWidth?: number
  flex?: number
  required?: boolean
  children: React.ReactNode
}) => {
  return <div className="layout-form-grid-col" style={{flex: props.flex || 1}}>
    <Form.Item label={props.label} required={props.required} labelCol={{flex: (props.labelWidth || 70) + 'px'}}>
      {props.children || '--'}
    </Form.Item>
  </div>
}

FormGrid.Item = (props: {
  label: React.ReactNode
  labelWidth?: number
  required?: boolean
  children: React.ReactNode
}) => {
  return <div className="layout-form-grid-col" style={{marginRight: 24}}>
    <Form.Item label={props.label} required={props.required} labelCol={{flex: (props.labelWidth || 70) + 'px'}}>
      {props.children || '--'}
    </Form.Item>
  </div>
}

export default FormGrid;