import React  from "react";
import FlexRow from "../flex-row/FlexRow";

const TreeTable = (props: {
  children: React.ReactNode
}) => {
  return <FlexRow style={{height: '100%'}}>
    <div style={{height: '100%'}}>
      {
        props.children? props.children[0] : null
      }
    </div>
    <div style={{flex: 1, overflow: 'hidden', marginLeft: 10}}>
      {
        props.children? props.children[1] : null
      }
    </div>
  </FlexRow>
}

TreeTable.Tree = (props) => {
  return <div style={{height: '100%'}}>
    {
      props.children
    }
  </div>
}

TreeTable.Table = (props) => {
  return <div style={{flex: 1, height: '100%', overflow: 'hidden', marginLeft: 10}}>
    {props.children}
  </div>
}

export default TreeTable;