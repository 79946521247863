import React from "react";
const styles = require('../layout-main.less');
import Title from "../title/Title";

const Full = (props) => {
  return <div className="full-page">
    {props.children}
  </div>
}

Full.Block = (props: {
  icon?: string
  title?: React.ReactNode
  style?: React.CSSProperties
  children: React.ReactNode
  extra?: React.ReactNode
}) => {
  return <div style={{
    background: '#fff',
    padding: 16,
    marginBottom: 10,
    ...(props.style || {})
  }}>
    {
      props.title && <Title icon={props.icon}>{props.title}</Title>
    }
    {props.children}
  </div>
}

export default Full;