import React from "react";

export default (props: {
  icon?: string
  children: React.ReactNode
  style?: React.CSSProperties
}) => {
  const icon = props.icon || 'icon-info';
  const style = props.style || {};
  return <div className="flex-row align-center" style={{marginBottom: 20, ...style}}>
    {
      icon &&
      <div className="flex-row align-center just-center" style={{
        width: 20,
        height: 20,
        background: '#68A6F6',
        color: '#fff',
        borderRadius: 25,
        overflow: 'hidden',
      }}>
        <i className={"iconfont " + icon} style={{fontSize:14}}/>
      </div>
    }
    <div style={{marginLeft: 5, fontSize: 16, lineHeight: 1, display: 'flex', alignItems: 'center'}}>{props.children}</div>
  </div>
}