import React from "react";

export default (props: {
  title?: string
  children?: React.ReactNode
}) => {
  return <div style={{marginBottom: 50}}>
    {
      props.title &&
      <div style={{fontSize: 14, fontWeight: 600}}>{props.title}</div>
    }
    {props.children}
  </div>
}