import React from "react";

export default (props: {
  style?: React.CSSProperties
  children?: React.ReactNode
}) => {
  const {style = {}} = props;
  return <div style={{display: 'flex', ...style}}>
    {props.children}
  </div>
};