import React from "react";
import Full from './full/Full';
import TreeTable from "./tree-table/TreeTable";
import FlexCol from "./flex-col/FlexCol";
import FlexRow from "./flex-row/FlexRow";
import FormGrid from "./form-grid/FormGird";
import FooterBar from './footer-bar/FooterBar';
import TabsContainer from "./tabs-container/TabsContainer";
import TabBarExtra from "./tab-bar-extra/TabBarExtra";
import Block from './block/Block';
import Button from './button/Button';
import Title from "./title/Title";
import classNames from "classnames";
const style = require('./layout-main.less');

export default {
  Full,
  FlexRow,
  FlexCol,
  FormGrid,
  FooterBar,
  TreeTable,
  TabsContainer,
  TabBarExtra,
  Block,
  Button,
  Title,
};