import React from "react";

const FlexCol = (props: {
  style?: React.CSSProperties
  children: React.ReactNode
}) => {
  const {style = {}} = props;
  return <div style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      ...style}
    }>
    {props.children}
  </div>
};

export default FlexCol;

FlexCol.Table = (props) => {
  return <div style={{flex: 1, overflow: 'hidden'}}>{props.children}</div>
}