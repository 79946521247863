import React, { PureComponent } from 'react';
import { Tabs, Badge, Button, message } from 'antd';
import MessageList from './MessageList';
import apis from '@/api/api';
import { Dialog } from '@/components';
import Layout from '@/layout/Layout';
import WebApi from '@/web/api';
const styles = require('./message.less');

export default class UserMessage extends PureComponent {

  private reg: MessageList | null = null;
  private order: MessageList | null = null;
  private sys: MessageList | null = null;
  state = {
    activeKey: 'reg',
    visible: false,
    reg: [],
    order: [],
    sys: [],
  };

  render() {

    const { activeKey, reg, order, sys } = this.state;

    return (
      <div className={styles.userMessage}>
        {
          reg.length > 0 || order.length > 0 || sys.length > 0 ? <div className={styles.header}>
            <Button type="default" icon={<i className="iconfont icon-close"/>} onClick={() => {
              this.clickCheckedAll(false);
            }}>
              取消
            </Button>
            <Button type="default" icon={<i className="iconfont icon-quanxuan"/>} onClick={() => {
              this.clickCheckedAll(true);
            }}>
              全选
            </Button>
            <span style={{display: 'inline-block', margin: '0 10px'}}>|</span>
            <Layout.Button.Delete onClick={this.clickDelete}>删除</Layout.Button.Delete>
            <Button type="default" icon={<i className="iconfont icon-yidu"/>} onClick={this.clickMarkRead}>标记为已读</Button>
          </div> : null
        }
        <Layout.TabsContainer>
          <Tabs
            size="small"
            activeKey={activeKey}
            defaultActiveKey="reg"
            animated={false}
            // tabBarExtraContent={<Button type="default" onClick={this.clickCheckBoxAll}>全选</Button>}
            onChange={key => this.setState({activeKey: key})}>

            {/* 注册消息 */}
            <Tabs.TabPane key="reg" tab="注册消息">
              <MessageList type="REGISTER"
                ref={r => this.reg = r}
                onChange={data => {
                  this.setState({
                    reg: data
                  });
                }}
                clickItem={this.clickMsgItem}/>
              
            </Tabs.TabPane>
            
            {/* 订单消息 */}
            <Tabs.TabPane key="order" tab="订单消息">

              <MessageList type="SALE"
                ref={r => this.order = r}
                onChange={data => {
                  this.setState({
                    order: data
                  });
                }}
                clickItem={this.clickMsgItem}/>
            </Tabs.TabPane>
            
            {/* 系统消息 */}
            {/* <Tabs.TabPane key="sys" tab="系统通知">
              <MessageList type="sys"
                ref={r => this.sys = r}
                onChange={data => {
                  this.setState({
                    sys: data
                  });
                }}
                clickItem={this.clickMsgItem}/>
            </Tabs.TabPane> */}
          </Tabs>
        </Layout.TabsContainer>
      </div>
    )
  }

  // 全选
  clickCheckedAll = (checked: boolean) => {
    const {activeKey} = this.state;
    if (checked === false) {
      this.setState({
        reg: [],
        order: [],
        sys: [],
      });
    }
    if (activeKey === 'reg') {
      this.reg?.checkedAll(checked);
      return;
    }
    if (activeKey === 'order') {
      this.order?.checkedAll(checked);
      return;
    }
    if (activeKey === 'sys') {
      this.sys?.checkedAll(checked);
      return;
    }
  }

  // 删除
  clickDelete = () => {
    Dialog.confirm({
      title: '删除确认',
      content: '确认删除吗？',
      onOk: () => {
        const {activeKey} = this.state;

        WebApi.message_delete({
          msgIdList: this.state[activeKey],
        })
        .then(() => {
          showSuccess('删除成功');
          this[activeKey].refresh();
        })
        .catch(e => {
          showError(e.message);
        });
      }
    });
  }

  // 标记为已读
  clickMarkRead = () => {
    Dialog.confirm({
      title: '确认',
      content: '确认标记为已读吗？',
      onOk: () => {
        const {activeKey} = this.state;
        WebApi.message_read_update({
          msgIdList: this.state[activeKey]
        })
        .then(() => {
          message.success('保存成功');
          // 刷新
          this[activeKey].refresh();
        })
        .catch(e => {
          showError(e.message);
        });
      }
    });
  }

  clickMsgItem = (item, index) => {
    if (!item.isRead) {
      WebApi.message_read_update({
        msgIdList: [ item.id ],
      })
      .then(() => {
        const {activeKey} = this.state;
        this[activeKey].refresh();
      })
      .catch(e => {
        showError(e.message);
      })
    }
  }
}
