import React from "react";
import { List, Checkbox, Badge } from 'antd';
import apis from "@/api/api";
import WebApi from "@/web/api";

const Title = (props) => {
  return <div onClick={props.onClick} style={{width: '100%', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
    <div style={{flex: 1, fontWeight: 'normal', fontSize: 14}}>
      <Badge status={!props.isRead ?  "error" : "default"} />
      {props.content}
    </div>
    <div style={{width: 200, textAlign: 'right', color: '#96a6b6', fontSize: 14, fontWeight: 'normal'}}>{props.createTime}</div>
  </div>
};

interface Props {
  type: IMessageType;
  clickItem(data?: any, index?: number): void
  onChange(data: string[]): void
}

export default class MessageList extends React.PureComponent<Props> {
  state: {
    dataSource: any[]
    total: number
    pageNum: number
  };

  private reqData: any

  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      total: 0,
      pageNum: 1,
    };

    this.reqData = {
      pageNum: 1,
      pageSize: 30,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  render() {
    const {dataSource, pageNum, total} = this.state;
    return <List
      style={{
        // height: '100%',
        // display: 'flex',
        // flexDirection: 'column'
      }}
      size="small"
      itemLayout="vertical"
      pagination={dataSource.length > 0 ? {
        current: pageNum,
        onChange: this.onChange,
        pageSize: 30,
        total: total,
        showSizeChanger: false,
        showTotal: total => `共 ${total} 条`
      } : false}
      dataSource={dataSource}
      renderItem={(item, index) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            avatar={<Checkbox style={{marginTop: 3}} checked={item.checked} onChange={(e) => {
              const next = [...this.state.dataSource];
              next[index].checked = e.target.checked;
              this.setState({
                dataSource: next
              });
              this.props.onChange(next.filter(n => n.checked).map(n => n.id));
            }}/>}
            title={<Title onClick={() => this.props.clickItem(item, index)}
            content={<span style={{fontSize: 12}}>
              {item.content}
            </span>}
            createTime={<span style={{fontSize: 12}}>
              {item.createTime}
            </span>}
            isRead={item.isRead}/>}
          />
        </List.Item>
      )}
    />
  }

  private fetch = () => {
    WebApi.message_paging({
      msgType: this.props.type,
      ...this.reqData,
    })
    .then((data: any) => {
      this.setState({
        dataSource: this.reqData.pageNum === 1 ? data.rows : this.state.dataSource.concat(data.rows),
        total: data.count,
        pageNum: this.reqData.pageNum
      });
    })
    .catch(e => {
      showError(e.message);
    });
  }

  private onChange = (pageNum, size) => {
    this.reqData.pageNum = pageNum;
    this.fetch();
  }

  public checkedAll = (checked: boolean) => {
    const next = [...this.state.dataSource].map(i => ({...i, checked}));
    this.setState({
      dataSource: next
    });
  }

  public refresh = () => {
    this.fetch();
  }
}